<template>
  <div @click="clicked" v-if="showldShow">
    <div
      class="card-course-item-container"
      :style="styleCard"
    >
      <div
        class="card-course-layer-0"
        :style="styleCard"
        >
        <img class="card-cover" :src="backgroundImage" alt="cover">
          <div
            class="card-course-layer-1"
            :style="styleCard"
          >
            <div v-if="!isMobile" class="card-hover-container" style="display: flex; flex-wrap: wrap">
              <div class="hover-container">
                <div class="hover-header">
                  <div 
                    class="nowrap" 
                    v-if="course.has_access && course.is_liberated"
                  >
                    <div class="hover-player-btn">
                      <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1.83167C1 1.0405 1.87525 0.562658 2.54076 0.990487L11.6915 6.87311C12.3038 7.26674 12.3038 8.16183 11.6915 8.55546L2.54076 14.4381C1.87525 14.8659 1 14.3881 1 13.5969V1.83167Z" stroke="#00483B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <div v-if="ratio.width > 200" class="hover-player-text">
                      {{ $t("cardcourse.play") }}
                    </div>
                  </div>
                  <div
                    class="hover-player-menu hover-player-text pr-3 pt-1"
                    @click.stop="$router.push(`/config_curso/${itemCourse.id}`)"
                    v-if="permission"
                  >
                    <!-- <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.75 7.75C2.16421 7.75 2.5 7.41421 2.5 7C2.5 6.58579 2.16421 6.25 1.75 6.25C1.33579 6.25 1 6.58579 1 7C1 7.41421 1.33579 7.75 1.75 7.75Z" stroke="#6F6F6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M1.75 2.5C2.16421 2.5 2.5 2.16421 2.5 1.75C2.5 1.33579 2.16421 1 1.75 1C1.33579 1 1 1.33579 1 1.75C1 2.16421 1.33579 2.5 1.75 2.5Z" stroke="#6F6F6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M1.75 13C2.16421 13 2.5 12.6642 2.5 12.25C2.5 11.8358 2.16421 11.5 1.75 11.5C1.33579 11.5 1 11.8358 1 12.25C1 12.6642 1.33579 13 1.75 13Z" stroke="#6F6F6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg> -->
                    <!-- <img src="@/assets/icons/edit2.svg" /> -->
                    <i class="fa fa-pencil mr-1"></i>
                    {{ $t("cardcourse.edit") }}
                  </div>
                  <div v-else></div>
                </div>
                <div class="hover-body">
                  <div style="flex-wrap: wrap;" class="hover-header">
                    <span class="hover-body-title">
                    {{ course.title }}
                    </span>
                    <span class="hover-lessons-count">
                    {{ course.lessons_count }} {{ $t("cardcourse.lessons") }}
                    </span>
                  </div>
                  <span v-if="course.description" class="hover-body-description">
                    {{ course.description.substr(0, 50) }}...
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="card-course-top-buttons">
            <div class="spaceRatingAdmModule" v-if="ratingHome">
              <div class="rating">
                <img src="@/assets/icons/star.svg" />
                <div v-if="itemCourse.rating !== null">{{ itemCourse.rating }}</div>
                <div v-else>0</div>
              </div>
            </div>
          </div>
          <is-available :isMobile="isMobile" :has-access="course.has_access" v-if="course.is_liberated"/>
          <div class="card-course-bottom-buttons pa-3" v-else>
            <div
              @click="clicked"
              class="card-course-btn-lock"
            >
              <img class="card-item-lock" src="lock.svg" alt="card-locked" />
            </div>
          </div>
        </img>
      </div>
    </div>
    <div v-if="showProgress" class="progress-course-bar-container">
      <div class="progress-course-bar-item">
        <div
          class="progress-course-bar-calc"
          :style="`width: ${getPercentage(course)}%`"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import notify from '@/services/libs/notificacao';
import IsAvailable from "@/components/home/IsAvailable.vue"
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    IsAvailable,
  },
  props: {
    showldShow: {
      type: Boolean,
      default: true,
    },
    course: {
      type: Object,
      default: () => {},
    },
    ratio: {
      type: Object,
      required: true
    },
    homeVertical: {
      type: Boolean,
      default: false,
    },
    permission: {
      type: Boolean,
      default: false,
    },
    isModule: {
      type: Boolean,
      default: false,
    },
    availible: {
      type: Object
    },
    showProgress: {
      type: Boolean,
      default: false
    },
    ratingHome: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters(['isDragging']),
    styleCard() {
      const scaleMobile = 1.5;
      if (this.isMobile) {
        return {
          width: `${this.ratio.width / scaleMobile}px`,
          height: `${this.ratio.height / scaleMobile}px`,
        };
      }
      return {
        width: `${this.ratio.width}px`,
        height: `${this.ratio.height}px`,
      };
    },
    itemCourse() {
      return this.isModule ? this.course.curso : this.course;
    },
    backgroundImage() {
      {
        if(this.isModule && this.course.cover==null){          
          return this.course.curso.cover.cdn_url;
        }else{
          return this.course.cover.cdn_url;
        } 
      }
    },
  },
  methods: {
    getPercentage(item) {
      const lessons_count = item.lessons_count;
      const lessons_completed_count = item.lessons_completed_count;
      const total_percentage = (lessons_completed_count / lessons_count) * 100;
      return total_percentage;
    },
    textColor(color) {
      if (color === '#000000') {
        return '#ffffff';
      }

      return '#000000';
    },
    clicked() {
      if (this.isDragging === true) {
        return;
      } 
      if (!this.course.has_access) {
        if (this.course?.course_id) {
          this.openModalBuy(this.course, true);
        } else {
          this.openModalBuy(this.course);
        }
        return;
      }
      if (!this.course.is_liberated) {
        notify('erro', this.$t('home2.course_template.error.liberation'));
        return
      }
      this.watch();
    },
    openModalBuy(data, module) {
      if (!data.is_liberated) {
        notify('erro', this.$t('home2.course_template.error.liberation'));
      } else {
        notify('erro', this.$t('home2.course_template.error.access'));
        if (module) {
          this.$root.$emit('buy-course-modal-v2', data.curso);
        } else {
          this.$root.$emit('buy-course-modal-v2', data);
        }
      }
    },
    watch(){
      if(!this.isModule){
        this.$router.push(`/curso/${this.itemCourse.id}`);
      }else{
        this.$router.push(`/curso/${this.itemCourse.id}/modulo/${this.course.id}`);  
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
.card-course-item-container {
  position: relative;
  border-radius: 7px;
  z-index: 20;
  margin-right: 7px;
  overflow: hidden;
  transition: scale 0.3 ease;
  background: var(--background-v2);
}
.card-course-item-container:hover {
}
.card-vertical {
  min-width: 128px;
  width: 128px;
  height: 165px;
}
.card-horizontal {
  min-width: 201px;
  width: 201px;
  height: 119px;
}
@media screen and (min-width: 768px) {
  .card-vertical {
    min-width: 236px;
    width: 236px;
    height: 304px;
  }
  .card-horizontal {
    min-width: 317px;
    width: 317px;
    height: 188px;
  }
}

.card-course-layer-0 {
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  left: 0;
  top: 0;
  border-radius: 6px;
  // background: rgba(0, 0, 0, 0.1);
  z-index: 22;
  position: absolute;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.3s ease;
}
.card-course-layer-0:hover {
  transform: scale(1.1);
  .card-course-top-buttons {
    visibility: hidden;
  }
  .card-course-layer-1 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 6px;
    z-index: 22;
    position: absolute;
    transition: background-color 0.3s ease;
    visibility: visible;
    margin-top: 0%;
  }
  .card-course-layer-1:hover {
    background-color: rgba(0, 0, 0, 0.3);
    .card-hover-container {
      bottom: 0%;
      visibility: visible;
      padding: 20px;
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
  .card-course-btn-available {
    visibility: hidden;
  }

}
.card-course-layer-1 {
  display: flex;
  flex-wrap: wrap;
  border-radius: 0px;
  z-index: 22;
  position: absolute;
  transition: background-color 0.3s ease;
  visibility: hidden;
}
.card-hover-container {
  background: var(--background2-v2);
  // background: linear-gradient(180deg, rgba(22, 24, 26, 0) 0%, #16181a 100%);
  display: flex;
  height: 55%;
  position: absolute;
  bottom: -55%;
  transition: bottom 0.3s ease;
  width: 100%;
}
.card-course-btn-available {
  height: 25px;
  border: none;
  white-space: nowrap;
  background: #3483fa;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #16181a;
  border-radius: 50px;
  padding-right: 13px;
  padding-left: 13px;
  transition: visibility ease;
}
.card-course-btn-view {
  height: 25px;
  border: none;
  white-space: nowrap;
  background: rgba(215, 215, 215, 0.1);
  backdrop-filter: blur(10px);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50px;
  padding-right: 13px;
  padding-left: 13px;
}
.card-course-btn-edit {
  height: 25px;
  border: none;
  white-space: nowrap;
  background: rgba(215, 215, 215, 0.1);
  backdrop-filter: blur(10px);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50px;
  padding-right: 13px;
  padding-left: 13px;
  margin-left: 13px;
}

.card-course-top-buttons {
  position: absolute;
  top: 0;
  display: flex;
  height: 40px;
  width: 100%;
  justify-content: flex-end;
}
.card-course-bottom-buttons {
  position: relative;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-end;
}

.green {
  background: var(--maincolor);
}

.card-course-btn-lock {
  max-width: 40px;
  max-height: 40px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(30, 32, 36, 0.5);
}
.card-item-lock {
  width: 13px;
  height: 15px;
}

.progress-course-bar-container {
  position: relative; 
  display: flex; 
  bottom: 0;
  width: 100%;
  padding-right: 7px; 
  margin-top: 7px;
}
.progress-course-bar-item {
  display: flex;
  border-radius: 6px;
  height: 2px; z-index: 80;
  width: 100%;
  background: var(--fontcolor2-v2);
}
.progress-course-bar-calc {
  display: flex; 
  border-radius: 6px; 
  height: 2px; 
  z-index: 81; 
  background: var(--maincolor);
}
.hover-player-btn {
  min-width: 35px;
  min-height: 35px;
  max-width: 35px;
  max-height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--maincolor);
}
.hover-player-menu {
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background2-v2);
  color: var(--fontcolor-v2)
}
.hover-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 40px;
  align-items: center;
}
.hover-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.hover-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--fontcolor-v2);
  margin-top: 15px;
}

.nowrap {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.hover-player-text {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  margin-left: 12px;
  text-align: left;
  color: var(--fontcolor-v2)
}

.hover-lessons-count {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--fontcolor2-v2);
  white-space: nowrap;
}

.hover-body-title {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.hover-body-description {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 10px;
  color: var(--fontcolor2-v2);
}

.card-cover {
  position: absolute; 
  min-height: 100%; 
  width: 100%;
  object-fit: cover;
}
</style>
